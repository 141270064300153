import './App.css';
import HomePage from './pages/HomePage';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BricksPage from './pages/BricksPage';
import CaulkingPage from './pages/CaulkingPage';
import ConcretePage from './pages/ConcretePage';
import WaterproofingPage from './pages/waterproofingpage';
import PaintingPage from './pages/PaintingPage';
import EifsStuccoPage from './pages/EifsStuccoPage';
import OurProjects from './pages/OurProjects';

function App() {
  return (
    <>
      <Helmet>
        <title>VBM Construction - Expert Restoration Services</title>
        <meta
          name='description'
          content='VBM Construction specializes in masonry, waterproofing, concrete repair, painting, and EIFS/stucco restoration for residential and commercial properties.'
        />
        <meta
          name='keywords'
          content='construction, masonry, waterproofing, concrete repair, caulking, painting, EIFS, stucco, restoration'
        />
      </Helmet>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/masonryandbrick' element={<BricksPage />} />
        <Route path='/caulking' element={<CaulkingPage />} />
        <Route path='/concrete' element={<ConcretePage />} />
        <Route path='/waterproofing' element={<WaterproofingPage />} />
        <Route path='/painting' element={<PaintingPage />} />
        <Route path='/eifsstucco' element={<EifsStuccoPage />} />
        <Route path='/projects' element={<OurProjects />} />
      </Routes>
    </>
  );
}

export default App;
